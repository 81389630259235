
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { Item } from '../store/models/ItemModel';
import {
  OrderlineDetails,
  Orderline,
  OrderlinePurchaseStateTypes,
  OrderPo
} from '../store/models/OrderlineModel';
import { OrdersService } from '@/services/orders-service';
import { CustomerService } from '@/services/customer-service';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import J1lImg from '@/components/J1lImg.vue';
import { PaymentService } from '@/services/payment-service';

@Component({
  components: { J1lImg }
})
export default class extends Vue {
  $refs!: {
    QuantityField: HTMLFormElement;
  };
  /* Properties */
  @Prop({ required: true }) orderlines!: any[];

  /* Store Actions */
  @Getter('getItemPartNumber', { namespace: 'items' })
  getPartNumbers: any;
  @Getter('orderlines', { namespace: 'orderlines' })
  loadedOrderlines!: any;
  @Getter('getCurrentOrder', { namespace: 'orderlines' })
  currentOrderlines!: OrderlineDetails[];
  @Mutation('orderlinesLoaded', { namespace: 'orderlines' })
  setOrderlines!: any;
  @Mutation('setCurrentOrder', { namespace: 'orderlines' })
  setCurrentOrder!: any;
  /* Watchers */

  @Watch('dialog')
  onDialogChange(val: any) {
    if (!val) {
      // this.closeDialog();
    }
  }
  @Watch('search')
  searching(val: string) {
    this.refreshImageForSearch();
  }

  /* Data */
  canChangePo: boolean = false;
  dialog: boolean = false;
  isGettingCustomerItems: boolean = false;
  isTextInputValid: boolean = false;
  isCalculatingPrice: boolean = false;
  po: string = '';
  quantity: number | null = null;
  refreshImg: boolean = true;
  search: string = '';
  typingTimer!: any;
  doneTypingInterval: number = 500;
  orderlinesToEdit!: any;
  extendedPrice: number = 0;
  variableDataFee: number = 0;
  rushFee: number = 0;
  setUpFee: number = 0;
  extraRollFee: number = 0;
  /* Methods */
  async openDialog() {
    await this.createPO();
    await this.GeneratePricingData();
    this.orderlinesToEdit = this.orderlines;
    this.dialog = true;
  }

  async createPO() {
    const response = await OrdersService.CreateDefaultPo();
    this.po = response.data;
  }
  async PlaceOrder() {
    // what should we do about payment
    const data = await PaymentService.GetCustomerPaymentProfiles();
    const defaultPayment = data.data.filter((p: any) => p.IsDefault == true);
    const profileId = defaultPayment[0].PaymentProfileId;
    let model = {
      Orderlines: this.currentOrderlines.map(ol =>
        ConversionHelper.convertOrderlineToAddModel(ol)
      ),
      PO: this.po,
      State: this.currentOrderlines.every(
        ol => ol.OrderlinePurchaseStateType === OrderlinePurchaseStateTypes.Create
      )
        ? 'New'
        : 'Edit',
      ShipToId: 4,
      PaymentMethodProfileId: defaultPayment[0].PaymentProfileId,
      OrderType: 'Reorder'
    };
    const orderResponse = await OrdersService.PlaceOrder(model);
    const newOrderline: Orderline[] = orderResponse.data.map(
      (r: any) => new Orderline(r)
    );
    await this.$store.dispatch('orderlines/ClearCurrentOrder');
    await this.$store.dispatch('orderlines/SetCurrentOrderInStore', newOrderline);

    if (this.loadedOrderlines[0]) {
      let orderlinesWithAddedOl = [...newOrderline, ...this.loadedOrderlines];
      this.setOrderlines(orderlinesWithAddedOl);
    }
  }
  async GeneratePricingData() {
    this.isCalculatingPrice = true;
    if (this.orderlines[0]) {
      var generatedOrderlines = [];
      for (let i = 0; i < this.orderlines.length; i++) {
        const model = ConversionHelper.generateNewOrderlineModel(
          this.orderlines[i].Item.Id,
          parseInt(this.orderlines[i].Quantity),
          this.orderlines[i].Address.Id,
          this.po
        );
        const generateOlResponse = await OrdersService.GenerateOrderlineDefaults(model);
        if (generateOlResponse.data) {
          const newOrderline = new OrderlineDetails(generateOlResponse.data);
          newOrderline.OrderLineNumber =
            (this.orderlines.length >= 1
              ? Math.max.apply(
                  Math,
                  (this.orderlines as OrderlineDetails[]).map(ol => {
                    return ol.OrderLineNumber;
                  })
                )
              : 0) + 1;
          newOrderline.OrderlinePurchaseStateType = OrderlinePurchaseStateTypes.Create;
          generatedOrderlines.push(newOrderline);
        }
      }

      const response = await OrdersService.CalculatePricingData(
        generatedOrderlines.map(ol => {
          ol.Quantity = Number(ol.Quantity);
          return ConversionHelper.convertOrderlineToAddModel(ol);
        })
      );
      const orderlines = response.data.map((ol: any) => new OrderlineDetails(ol));
      this.setCurrentOrder(orderlines);
      this.resetFees();
      response.data.forEach((ol: any) => {
        this.extendedPrice += ol.Quantity * ol.PriceEach;
        this.setUpFee += ol.SetupCosts;
        this.variableDataFee += ol.Quantity * ol.VariableDataPriceEach;
        this.rushFee += ol.RushFee;
        this.extraRollFee += ol.HandApplyFee;
      });
    } else {
      this.resetFees();
    }
    this.isCalculatingPrice = false;
  }
  resetFees() {
    this.extendedPrice = 0;
    this.setUpFee = 0;
    this.variableDataFee = 0;
    this.rushFee = 0;
    this.extraRollFee = 0;
  }
  /* Utility Functions */
  async adjustPricingInfo() {
    setTimeout(async () => {
      if (!this.isCalculatingPrice) {
        this.GeneratePricingData();
      } else {
        return;
      }
    }, 500);
  }
  filterObject(item: any, queryText: string, itemText: string) {
    return (
      item.PartNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
      item.Description.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }
  refreshImageForSearch() {
    this.refreshImg = false;
    setTimeout(() => (this.refreshImg = true), 0.5);
  }
  handleQuantityInput(newValue: any, index: number) {
    this.orderlines[index].Quantity = parseInt(newValue.toString().replace(/\D/g, ''));
    this.adjustPricingInfo();
  }
  formatStringLength(value: string, length: number) {
    if (value) {
      return `${value.substring(0, length)}${value.length >= length ? '...' : ''}`;
    }
    return '';
  }
  /* Computed */
  get formattedQuantity() {
    return (quantity: any) => {
      let returnValue =
        quantity != null ? quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
      return returnValue;
    };
  }
  get calculateSubTotal() {
    let price = (
      this.extendedPrice +
      this.setUpFee +
      this.variableDataFee +
      this.rushFee +
      this.extraRollFee
    ).toFixed(2);
    return price;
  }
  /* Mounted */
  /* Created */
  async created() {}
}
